<template>
	<div>
		<v-card class="shadow-blue pa30">
			<div class="d-flex align-center justify-center flex-column mb10">
				<h2>Performance globale</h2>
				<span class="ortho-text-gray">C'est ta moyenne de bonnes réponses</span>
			</div>
			<PerformanceChart :totalScoreInPercent="totalScoreInPercent" />
		</v-card>
		<v-card class="shadow-blue pa30 mt-5">
			<h2>Points gagnés</h2>
			<span class="ortho-text-gray">Les points gagnés sur les règles vues durant le programme</span>
			<p v-if="globalScoreRules == 1500" class="fs30 font-weight-bold mb-3 mt-3 text-right" style="color: #ffd15e">
				{{ globalScoreRules }}
				<img class="iconsBigRules" src="@/assets/icons/lightening-gold.svg" />/
				{{ globalTargetScoreRules }}
			</p>
			<p v-else class="fs30 font-weight-bold mb-3 mt-3 text-right" style="color: #34e5c8">
				{{ globalScoreRules }}
				<img class="iconsBigRules" src="@/assets/icons/lightening-green.svg" />/
				{{ globalTargetScoreRules }}
			</p>
			<v-progress-linear
				:value="(globalScoreRules / globalTargetScoreRules) * 100"
				:color="colorScore(globalScoreRules)"
				height="10"
				rounded
				background-color="#F1F1F1"
			></v-progress-linear>
		</v-card>
		<v-card class="shadow-blue pa30 mt-5" v-if="memorizedRules.length > 0" style="position: relative">
			<h2>
				{{ memorizedRules.length }}/{{
					memorizedRules.length + completedRules.length + uncompletedRules.length
				}}
				règle<span v-if="memorizedRules.length > 1">s</span> mémorisé<span v-if="memorizedRules.length > 1">s</span>
			</h2>
			<span class="ortho-text-gray">Félicitations, tu es le meilleur sur ces règles !</span>
			<div
				v-for="(rule, index) in memorizedRules"
				:key="index"
				class="border-section-completed pt-4 pb-4 d-flex justify-space-between align-center"
			>
				<div class="ruleInfo">
					<v-icon class="mr-1" color="#FFD15E">mdi-star</v-icon>
					<h4>{{ rule.name }}</h4>
				</div>

				<div>
					<span class="scoreInfoMemorized mr-1">
						{{ rule.score }}
						<img class="iconsRules" src="@/assets/icons/lightening-gold.svg" />
					</span>
				</div>
			</div>
		</v-card>
		<v-card class="shadow-blue pa30 mt-5" v-if="completedRules.length > 0" style="position: relative">
			<h2>
				{{ completedRules.length }} règle<span v-if="completedRules.length > 1">s</span> maîtrisée<span
					v-if="completedRules.length > 1"
					>s</span
				>
			</h2>
			<span class="ortho-text-gray">Objectif : mémorisez les règles en atteignant 150 points</span>
			<div
				v-for="(rule, index) in completedRules"
				:key="index"
				class="border-section-completed pt-4 pb-4 d-flex justify-space-between align-center"
			>
				<div class="ruleInfo">
					<v-icon class="mr-1" color="#34e5c8">mdi-checkbox-marked-circle</v-icon>
					<h4>{{ rule.name }}</h4>
				</div>

				<div>
					<span class="scoreInfoMaster mr-1">
						{{ rule.score }}
						<img class="iconsRules" src="@/assets/icons/lightening-green.svg" />
					</span>
				</div>
			</div>
		</v-card>
		<v-card class="shadow-blue pa30 mt-5" v-if="uncompletedRules.length > 0" style="position: relative">
			<h2>{{ uncompletedRules.length }} règle<span v-if="uncompletedRules.length > 1">s</span> à revoir</h2>
			<span class="ortho-text-gray">Cette règle est à revoir dans ton espace règles</span>
			<div class="text-center">
				<v-row>
					<v-col xl="6" md="4" sm="12" cols="12" v-for="(rule, index) in uncompletedRules" :key="index">
						<v-card class="shadow-blue-not-openned pa30 mt-5" style="height: 100%">
							<div class="d-flex flex-column justify-space-between" style="height: 100%">
								<h5 class="ortho-text-gray">{{ rule.familyTagName }}</h5>
								<h3>{{ rule.name }}</h3>
								<h5 class="text-uppercase ortho-text-gray">Maîtrisée par {{ randomNumber() }} % utilisateurs</h5>
							</div>
							<v-progress-linear
								:value="(rule.score / rule.pointTarget) * 100"
								height="10"
								color="#ff9800"
								background-color="#F1F1F1"
								style="position: absolute; bottom: 0px; left: 0px"
							></v-progress-linear>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</v-card>
	</div>
</template>

<script>
import PerformanceChart from '../../performance-chart/Index';

export default {
	name: 'ReportCategoryStep1',
	components: {
		PerformanceChart,
	},
	props: {
		totalScoreInPercent: Number,
		globalScoreRules: Number,
		globalTargetScoreRules: Number,
		scoresRules: Array,
	},
	computed: {
		uncompletedRules() {
			return this.scoresRules.filter((element) => {
				return element.score < 100;
			});
		},
		completedRules() {
			return this.scoresRules.filter((element) => {
				return element.score >= 100 && element.score < 150;
			});
		},
		memorizedRules() {
			return this.scoresRules.filter((element) => {
				return element.score == 150;
			});
		},
	},
	methods: {
		colorScore(value) {
			if (value == 1500) {
				//gold
				return '#FFD15E';
			} else {
				//green
				return '#34e5c8';
			}
		},
		randomNumber() {
			const min = 60;
			const max = 70;
			var number = Math.floor(Math.random() * (max - min + 1)) + min;
			return number;
		},
	},
};
</script>

<style lang="scss" scoped>
.ruleInfo {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.scoreInfoMemorized {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #ffd15e;
	font-weight: 700;
}
.scoreInfoMaster {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #34e5c8;
	font-weight: 700;
}
.iconsBigRules {
	width: 23px;
}
.iconsRules {
	width: 15px;
}
.icon-success {
	width: 23px;
}
.border-section-completed:not(:last-child) {
	border-bottom: 1px solid rgb(236, 236, 236);
}
</style>
